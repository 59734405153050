export default (data) => {
    if (!data) {
        return null
    }
    return {
        title: data.title || '',
        subtitle: data.subtitle || '',
        description: data.metricsItemDescription || ''
    }
}
