import {mapperModuleMedia, mapperModuleVideo, mapperModuleNavigationLink} from '../../../../core/data-layer/modules/mappers'
import type { MapperOptions } from '../../../../core/types'

export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    return {
        title: data.title || '',
        text: data.text || '',
        link: mapperModuleNavigationLink(data.link, options.getUrl),
        image: mapperModuleMedia(data.image, options.imageOptions),
        video: mapperModuleVideo(data.video, options.imageOptions)
    }
}
