import { mapperModuleNavigationLink } from '../../modules/mappers'
import { mapperIcon } from '../../utils/mappers/index'

export const mapperBlockCallToAction = (data: any, getUrl: Function) => {
  if (!data) {
    return null
  }
  return {
    contentType: data.contentType || '',
    icon: mapperIcon(data.icon),
    title: data.title || '',
    text: data.text || '',
    primaryLink: mapperModuleNavigationLink(data.primaryLink, getUrl),
    ...(data.secondaryLink && {
      secondaryLink: mapperModuleNavigationLink(data.secondaryLink, getUrl)
    })
  }
}
