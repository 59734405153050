import { mapperBlockRelatedContent, mapperBlockLandingLogoBar, mapperBlockCampaignsMedia } from '../../mappers'
import { mapperBlockLogoBar } from '../../../../core/data-layer/blocks/mappers'
import { mapperModuleMedia } from '../../../../core/data-layer//modules/mappers'
import type { MapperImageOptions, MapperOptions } from '../../../../core/types'

// TODO: check if this are the right image options for these
const imageOptionsRelatedContent: MapperImageOptions = {
    resize: true,
    lazy: true,
    sizes: {
        small: { width: 272, height: 153, aspectRatio: '16/9' },
        medium: { width: 576, height: 324, aspectRatio: '16/9' },
        large: { width: 576, height: 324, aspectRatio: '16/9' }
    }
}

export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    console.log('being called')

    const mapBlock = (item: any, options: MapperOptions) => {
        const mappersByContentType = {
            ModuleMedia: (data: any) => mapperModuleMedia(data, options.imageOptions),
            BlockLogoBar: (data: any) => mapperBlockLogoBar(data, options),
            BlockLandingLogoBar: (data: any) => mapperBlockLandingLogoBar(data, options),
            BlockCampaignsMedia: (data: any) => mapperBlockCampaignsMedia(data, options)
        }
        const mapper = mappersByContentType[item.contentType] || ((data: any) => data)
        if (!mappersByContentType[item.contentType]) {
            // eslint-disable-next-line no-console
            console.warn(`Mapper missing for content type for ${JSON.stringify(item)}`)
        }
        const mappedItem = mapper(item)
        mappedItem.contentType = item.contentType
        return mappedItem
    }

    return {
        title: data.title || '',
        description: data.description || '',
        contentBlocks: data.contentBlocksCollection?.items?.filter(Boolean).map((item: any) => mapBlock(item, options)) || [],
        relatedContent: mapperBlockRelatedContent(data.relatedContent, { ...options, imageOptions: imageOptionsRelatedContent }),
        downloadMedia: mapperModuleMedia(data.directDownloadMedia, {})
    }
}
