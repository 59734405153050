<template>
  <div class="ds-rich-text">
    <!-- <client-only> -->
    <ContentfulRichTextRenderer v-if="doc.content" :key="key" :document="doc" :node-renderers="renderNodes" :mark-renderers="renderMarks" />
    <!-- </client-only> -->
  </div>
</template>

<script setup>
import { h } from 'vue'
import { DsImageElement, DsImageWithFooter, DsVideoPlayer, DsTable, DsCodeSnippet, DsQuote, DsCardBlog, DsMediaTextCard } from 'awds'

import { kebabCase } from 'lodash-es'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import ContentfulRichTextRenderer from 'contentful-rich-text-vue-renderer'
import FaqGroup from './faq-group/faq-group.vue'
import { richTextEntryCollectionQuery } from '@/data-layer/entry/query-entry'
import { mapperModuleNavigationLink } from '@/data-layer/modules/mappers'
import { useRoutesStore } from '~/composables/useRoutesStore'
const {
  createTableModuleElement,
  getLinkedEntriesIds,
  mapImageProps,
  mapVideoProps,
  mapTableNodeToDsTableData,
  mapCodeSnippetProps,
  mapQuoteProps,
  mapCardBlogProps,
  mapMediaTextCardProps,
  mapHeadingProps
} = useRichTextUtils()

const { getLocaleIso, getI18nFormatterLocale, locales } = useLocales()
const { locale: appLocale, t } = useI18n()
const { queryContentful } = useQueries()
const { isScreenSmall, screen } = useScreen()
const { labels } = useGlobalData()
const ready = ref(false)
const key = ref(1)

const { routes, retrieveRoutes } = useRoutesStore()
const preview = useRuntimeConfig().public.preview
const props = defineProps({
  data: { type: Object, required: true },
  contentLocale: { type: String, default: null },
  tableType: { type: String, default: '' },
  tableTextSize: { type: String, default: 'small' },
  isFirstColumnHeader: { type: Boolean, default: true }
})
const linkedEntries = ref([])

// const ready = ref(false)
const computedScreenSize = computed(() => {
  // We need to hack the screen size, because the content is always in a limited width like a tablet
  return isScreenSmall.value ? 'small' : 'medium'
})
const locale = getLocaleIso(props.contentLocale || appLocale.value)

// We are going to execute this in a blocking way
const doc = computed(() => {
  return props.data?.json || { content: [] }
})

// Get embedded entry data in advance
const linkedEntriesIds = getLinkedEntriesIds(doc.value.content)

// ASYNC Data
await retrieveRoutes()
const entries = await fetchLinkedEntries()

linkedEntries.value = entries

const renderNodes = computed(() => {
  const { getInternalUrl, getUrl } = useRoutes(routes.value, appLocale)
  const genericOptions = {
    getUrl,
    getInternalUrl,
    locale,
    labels: labels.value || {}
  }

  return {
    [INLINES.ENTRY_HYPERLINK]: (node) => {
      // linkedEntries = []
      const entry = linkedEntries.value?.find(({ sys }) => sys.id === node.data.target.sys.id)
      let props
      switch (entry?.contentType) {
        // @TODO [POST_MIGRATION] - Remove case `PagePlaceholder`
        case 'PagePlaceholder':
          // Internal page link props
          props = {
            href: getInternalUrl(node.data.target.sys.id),
            // Prevent the `href` behaviour
            // Delay the redirection to the link url to let the tracking happen
            onclick: `
                    window.event.preventDefault();
                    setTimeout(() => {
                      window.document.location = '${getInternalUrl(node.data.target.sys.id)}';
                    }, 300);
                  `
          }
          break
        case 'ModuleChapter':
          // On page link props
          props = {
            href: `#${kebabCase(entry.title)}`,
            onclick: `
                    window.scrollTo({
                      left: 0,
                      top: document.querySelector('[data-id=${kebabCase(entry.title)}]').offsetTop,
                      behavior: 'smooth'
                    });
                  `
          }
          break
        case 'ModuleMedia':
          // Download link props
          props = {
            href: entry.src?.[0]?.original,
            download: '',
            target: '_blank'
          }
          break
        case 'ModuleNavigationLink':
          // Download link props
          props = {
            href: mapperModuleNavigationLink(entry, getUrl).url
          }
          break
        default:
          props = {}
      }
      const text = node.content[0].value
      return h('a', { ...props }, text)
    },
    // External link
    [INLINES.HYPERLINK]: (node) => {
      const text = node.content[0].value
      const url = node.data?.uri
      const isInternal = url.startsWith('/') || url.startsWith('#')
      const attrs = {
        href: url,
        ...!isInternal && { target: '_blank', rel: 'noopener' }
      }
      return h('a', { ...attrs }, text)
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const entry = linkedEntries.value?.find(({ sys }) => sys.id === node.data.target.sys.id)

      const contentType = entry?.contentType

      switch (contentType) {
        case 'ModuleTable': {
          const html = createTableModuleElement(entry).outerHTML
          return h('div', { innerHTML: html })
        }
        case 'BlockFaqGroup': {
          const { title, itemsCollection: { items }, description } = entry
          return h(FaqGroup, { title, items, description })
        }
        case 'ModuleMedia': {
          const imageOptions = {
            sizes: {
              small: { width: 327, height: 'auto', aspectRatio: '16/9' },
              medium: { width: 672, height: 'auto', aspectRatio: '16/9' },
              large: { width: 784, height: 'auto', aspectRatio: '16/9' }
            },
            resize: true,
            lazy: true
          }

          return h(DsImageWithFooter, { ...mapImageProps(entry, imageOptions), class: 'ds-rich-text__spacing' })
        }
        case 'ModuleVideo':
        case 'ModuleInternalVideo': {
          return h(DsVideoPlayer, { ...mapVideoProps(entry, { ...genericOptions, imageOptions: { resize: true, lazy: true } }), class: 'ds-rich-text__spacing' })
        }
        case 'ModuleCodeSnippet': {
          return h(DsCodeSnippet, { ...mapCodeSnippetProps(entry, t('programmingLanguage')), class: 'ds-rich-text__spacing' })
        }
        case 'BlockQuote': {
          const options = {
            ...genericOptions,
            imageOptions: {
              sizes: {
                small: { width: 327, height: 327, aspectRatio: '1/1' },
                medium: { width: 264, height: 352, aspectRatio: '3/4' },
                large: { width: 264, height: 352, aspectRatio: '3/4' }
              },
              resize: true,
              lazy: true
            }
          }
          return h(DsQuote, { ...mapQuoteProps(entry, options), screen: computedScreenSize.value, class: 'ds-rich-text__spacing' })
        }
        case 'PageKnowledgeHubDetail': {
          const options = {
            ...genericOptions,
            imageOptions: {
              sizes: {
                small: { width: 327, height: 184, aspectRatio: '16/9' },
                medium: { width: 264, height: 264, aspectRatio: '1/1' },
                large: { width: 264, height: 264, aspectRatio: '1/1' }
              },
              resize: true,
              lazy: true
            }
          }

          const mappedProps = mapCardBlogProps(entry, options)
          return h(DsCardBlog, {
            ...mappedProps,
            screen: computedScreenSize.value,
            subtitle: `${mappedProps.dateFormatted} · ${t('minutesAmount', mappedProps.minutesToRead)}`,
            class: 'ds-rich-text__spacing'
          })
        }
        // TODO - MIGRATE BlockCallToActionWithMedia -> Commmented out to stop breaking the preview of some article pages
        // case 'BlockCallToActionWithMedia': {
        //   const options = {
        //     ...genericOptions,
        //     imageOptions: {
        //       sizes: {
        //         small: { width: 327, height: 184, aspectRatio: '16/9' },
        //         medium: { width: 264, height: 264, aspectRatio: '1/1' },
        //         large: { width: 264, height: 264, aspectRatio: '1/1' }
        //       },
        //       resize: true,
        //       lazy: true
        //     }
        //   }
        //   // Prevent error for draft content when image is missing
        //   if (!mappedProps.image) {
        //     return ''
        //   }
        //   return h(DsMediaTextCard, {
        //     ...mapMediaTextCardProps(entry, options),
        //     screen: computedScreenSize.value,
        //     class: 'ds-rich-text__spacing'
        //   })
        // }
      }
      return ''
    },
    [BLOCKS.TABLE]: (node, key) => {
      const { columns, rows, type } = mapTableNodeToDsTableData(node, props.isFirstColumnHeader)

      // TODO DsTable
      return h('DsTable', {
        key,
        title: '',
        textSize: props.tableTextSize,
        columns,
        isFirstColumnHeader: props.isFirstColumnHeader,
        rows,
        screen,
        type: props.tableType || type
      })
    },
    [BLOCKS.HEADING_1]: (node, key) => {
      const headingProps = mapHeadingProps(node, { hTag: 'h1', highlightSupport: false })
      return h('h1', headingProps.attributes, headingProps.text)
    },
    [BLOCKS.HEADING_2]: (node, key) => {
      const headingProps = mapHeadingProps(node, { hTag: 'h2', highlightSupport: false })
      return h('h2', headingProps.attributes, headingProps.text)
    },
    [BLOCKS.HEADING_3]: (node, key) => {
      const headingProps = mapHeadingProps(node, { hTag: 'h3', highlightSupport: true })
      return h('h3', headingProps.attributes, headingProps.text)
    },
    [BLOCKS.HEADING_4]: (node, key) => {
      const headingProps = mapHeadingProps(node, { hTag: 'h4', highlightSupport: true })
      return h('h4', headingProps.attributes, headingProps.text)
    },
    [BLOCKS.HEADING_5]: (node, key) => {
      const headingProps = mapHeadingProps(node, { hTag: 'h5', highlightSupport: true })
      return h('h5', headingProps.attributes, headingProps.text)
    },
    [BLOCKS.HEADING_6]: (node, key) => {
      const headingProps = mapHeadingProps(node, { hTag: 'h6', highlightSupport: true })
      return h('h6', headingProps.attributes, headingProps.text)
    }
  }
})

watch(linkedEntries, () => {
  key.value += 1
})

const renderMarks = computed(() => {
  return {
    italic: (text, key) => h('i', { key }, text),
    superscript: (text, key, h) => h('sup', { key }, text),
    subscript: (text, key, h) => h('sub', { key }, text)
  }
})

async function fetchLinkedEntries () {
  if (linkedEntriesIds.size === 0) {
    return []
  }
  const entryPromises = [...linkedEntriesIds].map(async (id) => {
    try {
      const data = await queryContentful({
        query: richTextEntryCollectionQuery({ locales, preview }),
        variables: { id, locale, preview } // TODO: Replace with composable
      }, { log: false })

      return data?.entryCollection?.items?.[0] || null
    } catch (e) {
      throw new Error(`Fetch Entry with ID ${id} failed! - ${e}`)
    }
  })
  const entries = await Promise.all(entryPromises) || []

  return entries.flat().filter(Boolean) || []
}

onBeforeMount(() => {

})

ready.value = true
</script>

<script>
export default {
  name: 'ContentfulRichTextRenderer'
}
</script>

<style lang="scss">
@import 'awds/foundation-mixins.scss';
@import 'awds/foundation-vars.scss';

$table-icon-column-width: 100px;
$table-icon-line-height: 20px;

.ds-rich-text {
  @include rich-text;

  &__spacing {
    margin-bottom: $spacing-48;
    margin-top: $spacing-48;
  }
}

/* stylelint-disable selector-max-compound-selectors */
.ds-rich-text :deep(.ds-table),
.ds-rich-text :deep(.ds-table-list),
.ds-rich-text :deep(table) {
  margin-top: $spacing-12;
  .table-column--fixed-width {
    vertical-align: middle;
    width: $table-icon-column-width;

    p {
      text-align: center;
    }
  }

  .table-icon {
    border-radius: $border-radius-12;
    color: $color-white;
    display: inline-block;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    line-height: $table-icon-line-height;
    text-align: center;
    width: $table-icon-line-height;

    &.P {
      background-color: $color-red-900;
    }

    &.R {
      background-color: $color-orange-500;
    }

    &.V {
      $inline-color-green: '%23#{$green-1100-code}';

      background: url('data:image/svg+xml;utf8,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.9991C0 4.92401 4.92487 -0.000854492 11 -0.000854492C17.0751 -0.000854492 22 4.92401 22 10.9991C22 17.0743 17.0751 21.9991 11 21.9991C4.92487 21.9991 0 17.0743 0 10.9991ZM16.2071 7.29204C16.5976 7.68256 16.5976 8.31573 16.2071 8.70625L10.2071 14.7063C9.8654 15.048 9.33792 15.0907 8.94977 14.8344C8.89432 14.7978 8.84112 14.7545 8.79231 14.7057L5.79289 11.7063C5.40237 11.3157 5.40237 10.6826 5.79289 10.292C6.18342 9.90152 6.81658 9.90152 7.20711 10.292L9.5 12.5849L14.7929 7.29204C15.1834 6.90151 15.8166 6.90151 16.2071 7.29204Z" fill="#{$inline-color-green}"/></svg>') no-repeat left center;
      height: $spacing-24;
      width: $spacing-24;
    }

    &.X {
      $inline-color-grey: '%23#{$grey-100-code}';

      background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12.0007C1 5.9256 5.92487 1.00073 12 1.00073C18.0751 1.00073 23 5.9256 23 12.0007C23 18.0759 18.0751 23.0007 12 23.0007C5.92487 23.0007 1 18.0759 1 12.0007ZM8.29289 8.29363C8.68342 7.9031 9.31658 7.9031 9.70711 8.29363L12 10.5865L14.2929 8.29363C14.6834 7.9031 15.3166 7.9031 15.7071 8.29363C16.0976 8.68415 16.0976 9.31732 15.7071 9.70784L13.4142 12.0007L15.7071 14.2936C16.0976 14.6841 16.0976 15.3173 15.7071 15.7078C15.3166 16.0984 14.6834 16.0984 14.2929 15.7078L12 13.4149L9.70711 15.7078C9.31658 16.0984 8.68342 16.0984 8.29289 15.7078C7.90237 15.3173 7.90237 14.6841 8.29289 14.2936L10.5858 12.0007L8.29289 9.70784C7.90237 9.31732 7.90237 8.68415 8.29289 8.29363Z" fill="#{$inline-color-grey}"/> </svg>') no-repeat left center;
      height: $spacing-24;
      width: $spacing-24;
    }

    &.G {
      background: $color-green;
      color: transparent;
      margin-right: 4px;
    }

    &.HG {
      background-image: linear-gradient(to right, $color-green 50%, $color-white 50%);
      color: transparent;
      margin-right: 4px;
    }

    &.B {
      background: $color-blue;
      color: transparent;
      margin-right: 4px;
    }

    &.RE {
      background: $color-red;
      color: transparent;
      margin-right: 4px;
    }

    &.W {
      background: $color-white;
      color: transparent;
      margin-right: 4px;
      border: 1px solid black;
    }

    &.GR {
      background: $color-grey-500;
      color: transparent;
      margin-right: 4px;
    }
  }
}
/* stylelint-enable selector-max-compound-selectors */
</style>
