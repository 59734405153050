/**
 * Module Metric mapper
 * @param {Object} data - content data
 * @return {Object} - normalized data
 */
export const mapperModuleMetric = (data: any) => {
  if (!data) {
    return null
  }

  const getSafeMetric = (metric: string) => {
    if (!metric) {
      return { value: '', isPercentageValue: false }
    }

    const trimmedString = metric.trim()
    const withPercentage = trimmedString.endsWith('%')

    if (withPercentage) {
      return {
        value: Number(trimmedString.substring(0, trimmedString.length - 1)),
        isPercentageValue: true
      }
    }

    return {
      value: metric,
      isPercentageValue: false
    }
  }

  const metricData = getSafeMetric(data.metric)
  return {
    id: data.sys?.id || '',
    contentType: data.contentType || '',
    originalMetric: data.metric || '',
    metric: metricData.value || '',
    isPercentageValue: metricData.isPercentageValue || false,
    description: data.description || '',
    extraInformation: data.extraInformation || null
  }
}
